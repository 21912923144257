<template>
	<div>
		<div class="ft20 cl-main ftw600">服务项目管理</div>
		
		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="项目名称">
						<a-input v-model="search.name" placeholder="请输入项目名称"></a-input>
					</a-form-item>
							
					<a-form-item label="项目分类">
						<div>
							<a-tree-select v-model="search.project_type_id" style="width: 200px"
										   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="type_list"
										   placeholder="请选择" >
							</a-tree-select>

						</div>
					</a-form-item>
					<a-form-item   label="是否推荐" >
						<a-select style="width: 100px"  v-model="search.is_tui">
							<a-select-option value="">
								全部
							</a-select-option>
							<a-select-option value="0">
								否
							</a-select-option>
							<a-select-option value="1">
								是
							</a-select-option>

						</a-select>
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		
		<div class="mt20">
			<div class="bg-w">
				<div class="pd30">
					<div>
						<a-button type="primary" icon="plus" @click="addProjectAct()">添加项目</a-button>
					</div>
					<div class="mt30">
						<a-radio-group v-model="search.audit_status" button-style="solid" @change="changeStatus">
							<a-radio-button :value="1">
								已审核
							</a-radio-button>
							<a-radio-button :value="0">
								待审核
							</a-radio-button>
							<a-radio-button :value="-1">
								已退回
							</a-radio-button>
						</a-radio-group>
					</div>
					<div class="mt30">
						<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
							<a-radio-button :value="1">
							  上架中
							</a-radio-button>
							<a-radio-button :value="-1">
							  已下架
							</a-radio-button>
						</a-radio-group>
					</div>
				
					<div class="mt20">
						<div class="wxb-table-gray">
							<a-table rowKey="project_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
								:data-source="datas" :loading="loading">
								
								<div class="flex left " slot="project" slot-scope="record">
									<img :src="record.cover_img"  class="projetc-index-cover-img" />
									<div class="ml5" style="text-align: left;">
										<div>{{record.name}}</div>
									</div>
								</div>

								<div class="flex left" slot="site_name" slot-scope="site_name">
									<a-tag v-if="site_name" color="purple">
										{{ site_name }}
									</a-tag>
									<a-tag v-else color="green">平台服务</a-tag>
								</div>
								<div class="flex left" slot="type" slot-scope="type,record">
									{{type != null ? type.name : '暂无分类'}}
								</div>
								
								<div class="flex right" slot="price" slot-scope="price,record">
									¥{{price}}
								</div>
								
								<div class="right" slot="market_price" slot-scope="market_price,record">
									¥{{market_price}}
								</div>
				
								<template slot="action" slot-scope="record,index">
									<div class="flex center">
										<a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
											<a-menu slot="overlay">
												<a-menu-item>
													<a class="menu-act" href="javascript:;" @click="editProjectAct(record)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">编辑</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="record.status==1" >
													<a class="menu-act" href="javascript:;" @click="undercarriage(record)">
														<i class="iconfont ft14 iconxiajia"></i>
														<span class="ml10">下架</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="record.status==-1">
													<a class="menu-act" href="javascript:;" @click="grounding(record)">
														<i class="iconfont ft14 iconshangjia"></i>
														<span class="ml10">上架</span>
													</a>
												</a-menu-item>
												<a-menu-item v-if="record.status==-1">
													<a class="menu-act" href="javascript:;" @click="delProjectAct(record)">
														<i class="iconfont ft14 icondelete"></i>
														<span class="ml10">删除</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</template>
							</a-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default{
		mixins: [listMixin],
		data(){
			return{
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
                    showTotal:false,
				},
				type_list:[],
				search:{
					name:'',
					project_type_id:undefined,
					status:1,
                    is_tui:'',
                    audit_status:1,
				},
				columns: [
				],
				datas: [],
			}
		},
		methods:{

			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getProjectList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
					project_type_id:this.search.project_type_id,
					status:this.search.status,
                    is_tui:this.search.is_tui,
                    audit_status:this.search.audit_status,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
                    this.pagination.showTotal=(total)=>"共"+res.total+"条";
					if(res.type_list){
						this.type_list=res.type_list;
					}
					if(this.columns.length<=0){
					    this.columns=res.columns;
					}
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
			undercarriage(record){
				this.$confirm({
					title:'确认下架这个项目吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/undercarriageProject',{
								project_id:record.project_id,
							}).then(res=>{
								this.$message.success('下架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			grounding(record){
				this.$confirm({
					title:'确认上架这个项目吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/groundingProject',{
								project_id:record.project_id,
							}).then(res=>{
								this.$message.success('上架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			delProjectAct(record){
				this.$confirm({
					title:'确认删除这个项目吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delProject',{
								project_id:record.project_id,
							}).then(res=>{
								this.$message.success('删除成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			changeStatus(){
				this.pagination.current=1;
				this.getLists();
			},
			
			addProjectAct(){
				this.$router.push('/project/edit');
			},
			editProjectAct(record){
				this.$router.push('/project/edit?id='+record.project_id);
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-index-cover-img{
		width: 60px;
		height: 40px;
	}
</style>
